import React, { useMemo, useState, useCallback } from 'react';

import { getRandomString } from 'utils/';
import {
  StyledTable,
  StyledTableData,
  StyledTableHead,
  StyledTableBody,
  StyledTableFoot,
} from 'components/Table/Table/Table.styled';
import {
  StyledTableHeader,
  StyledTableHeadRow,
} from 'components/Table/Header/Header.styled';
import { SelectInput } from 'components/Inputs/';
import {
  StyledUnit,
  StyledInput,
  StyledOption,
  StyledWrapper,
  StyledQuantity,
  StyledTableRow,
  StyledRoundIcon,
  StyledRowButton,
  StyledAttribute,
  StyledRoundButton,
  StyledErrorMessage,
  StyledDeleteButton,
  StyledSubTableData,
  StyledCurrencyInput,
  StyledQuantityWrapper,
} from 'components/Order/Catalogue/Catalogue.styled';
import { ReactComponent as TrashIcon } from 'icons/delete.svg';

function Catalogue({
  type,
  total,
  message,
  currency,
  inventory,
  catalogue,
  warehouses,
  isReadOnly,
  messageRef,
  currencyRef,
  setCatalogue,
  emptyCatalogue,
  getItemDetails,
  includeLeasePrice,
}) {
  const error = {
    id: '',
    name: '',
    quantity: '',
  };

  const [errors, setErrors] = useState(error);

  const currencyOptions = useMemo(() => {
    if (currency && typeof currency === 'string') {
      return [currency];
    }
    if (currency && currency.length > 0) {
      return currency;
    }
    return [];
  }, [currency]);

  const totalItems = useMemo(() => {
    return catalogue?.reduce(function (acc, { quantity }) {
      return acc + quantity;
    }, 0);
  }, [catalogue]);

  const itemOptions = useMemo(() => {
    if (inventory && inventory.length > 0) {
      let items = inventory;
      if (type) {
        items = inventory.filter(function (item) {
          return item.item_order_type === type;
        });
      }
      if (warehouses && warehouses.length > 0) {
        items = items.filter(function (item) {
          return warehouses.includes(item.warehouse_id);
        });
      }
      const catIds = catalogue.map(function ({ itemId }) {
        return itemId;
      });
      const attributeIds = catalogue.map(function ({ attributes = [] }) {
        return attributes.map(function ({ id }) {
          return id;
        });
      });
      const uniqueAttributes = items.map(function (item) {
        if (Array.isArray(item.attributes) && item.attributes.length > 0) {
          const attributes = item.attributes.filter(function ({ id }) {
            return !attributeIds.flat().includes(id);
          });
          return {
            ...item,
            attributes,
          };
        }
        return item;
      });
      const filtered = uniqueAttributes.filter(function (item) {
        if (Array.isArray(item.attributes) && item.attributes.length > 0) {
          return item;
        }
        return !catIds.includes(item.id) && item.quantity > 0;
      });
      const options = filtered.map(function (item) {
        const {
          id,
          quantity,
          attributes,
          item_name: name,
          quantity_unit: unit,
        } = item;
        const label = `${name} (${quantity} ${unit})`;
        const allAttributesZero = attributes.every(function ({ quantity }) {
          return quantity === 0;
        });
        if (attributes.length === 0 || allAttributesZero) {
          return {
            label,
            value: id,
          };
        }
        return {
          label,
          options: formatAttributes(id, attributes),
        };
      });
      return options;
    }
    return [];
  }, [type, catalogue, inventory, warehouses]);

  /**
   * @param {string} id
   * @param {[]} attributes
   */
  function formatAttributes(id, attributes) {
    const filtered = attributes.filter(function (attr) {
      return attr?.quantity > 0;
    });
    const formatted = filtered?.map(function (attr) {
      return {
        value: `${id}_${attr.id}`,
        label: `> ${attr.attribute_name} - ${attr.attribute_value}`,
      };
    });
    return formatted;
  }

  /**
   * @param {{
   * label: string
   * value: string
   * }} option
   * @param {{ name: string }} input
   */
  function onItemChange(option, input) {
    if (option) {
      const { value } = option;
      const [itemId, attributeId] = value.split('_');
      const {
        unit,
        name,
        price,
        stock,
        itemCode,
        attributeName,
        attributeValue,
      } = getItemDetails(itemId, attributeId);
      const attribute = `${attributeName} - ${attributeValue}`;
      function getNextAttribute(current) {
        if (!attributeId) {
          return current;
        }
        return [
          ...current,
          {
            itemCode,
            quantity: 1,
            id: attributeId,
            name: attribute,
          },
        ];
      }
      const nextCat = catalogue.map(function (row) {
        const attributes = getNextAttribute(row.attributes);
        if (row.id === input.name) {
          return {
            ...row,
            name,
            unit,
            price,
            stock,
            itemId,
            attributes,
            quantity: attributes.length || 1,
          };
        }
        return row;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * @param {string} id
   * @param {string} sign
   */
  function onQuantityChange(id, sign) {
    const errorObject = {
      id,
      name: '',
      quantity: '',
    };
    if (sign === '+') {
      let isValid = true;
      const nextRows = catalogue.map(function (row) {
        if (row.id === id) {
          if (!row.name) {
            isValid = false;
            errorObject.name = 'Item name is required';
            return row;
          } else {
            errorObject.name = '';
          }
          if (row.quantity + 1 >= row.stock) {
            isValid = false;
            errorObject.quantity = 'Quantity exceed current stock';
            return row;
          } else {
            errorObject.quantity = '';
          }
          return {
            ...row,
            quantity: row.quantity + 1,
          };
        }
        return row;
      });
      setErrors(errorObject);
      if (isValid) {
        setCatalogue(nextRows);
      }
    } else {
      setErrors(errorObject);
      const nextCat = catalogue.map(function (row) {
        if (row.id === id) {
          const { attributes } = row;
          if (row.quantity - 1 < attributes.length) {
            return row;
          }
          return {
            ...row,
            quantity: row.quantity - 1,
          };
        }
        return row;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * Validates the last row before adding a new one.
   */
  const isFormValid = useCallback(() => {
    const errorObject = {};
    const { length } = catalogue;
    // grab either the first row or the last row
    const row = length === 1 ? catalogue[0] : catalogue[length - 1];
    const { id, name, quantity } = row;
    if (!name) {
      errorObject.name = 'Item name is required';
    } else {
      errorObject.name = '';
    }
    if (!quantity) {
      errorObject.quantity = 'Quantity is required';
    } else {
      errorObject.quantity = '';
    }
    const hasError = Object.values(errorObject).some(function (value) {
      return value.length > 0;
    });
    if (hasError) {
      setErrors({ ...errorObject, id });
      return false;
    }
    setErrors(error);
    return true;
  }, [catalogue, errors, onAddItem]);

  function onAddItem() {
    if (isFormValid()) {
      setCatalogue([
        ...catalogue,
        {
          ...emptyCatalogue,
          id: getRandomString(),
        },
      ]);
    }
  }

  /**
   * @param {string} itemId
   * @param {string} attributeId
   */
  function onRemoveItem(itemId, attributeId) {
    if (attributeId) {
      const nextCat = catalogue.map(function (row) {
        if (row.id === itemId) {
          const attributes = row.attributes.filter(function ({ id }) {
            return id !== attributeId;
          });
          return {
            ...row,
            attributes,
            quantity: attributes.length,
          };
        }
        return row;
      });
      setCatalogue(nextCat);
    } else {
      const nextCat = catalogue.filter(function (row) {
        return row.id !== itemId;
      });
      setCatalogue(nextCat);
    }
  }

  /**
   * @param {{
   * currentTarget: {
   * name: string
   * value: string
   * }
   * }} event
   */
  function onInputChange(event) {
    const { currentTarget } = event;
    const { id, name, value } = currentTarget;
    const [, itemId] = id.split('_');
    const nextCat = catalogue.map(function (row) {
      if (row.id === itemId) {
        return {
          ...row,
          [name]: +value, // to process non-number values, modify logic or use a separate handler function
        };
      }
      return row;
    });
    setCatalogue(nextCat);
  }

  return (
    <StyledWrapper>
      {message ? (
        <StyledErrorMessage $main aria-live="polite" ref={messageRef}>
          {message}
        </StyledErrorMessage>
      ) : null}
      <StyledTable>
        <StyledTableHead>
          <StyledTableHeadRow>
            <StyledTableHeader>Product Name</StyledTableHeader>
            <StyledTableHeader>Attribute</StyledTableHeader>
            <StyledTableHeader>Qty</StyledTableHeader>
            {!includeLeasePrice ? null : (
              <>
                <StyledTableHeader>Item Price</StyledTableHeader>
                <StyledTableHeader>Total Price</StyledTableHeader>
              </>
            )}
            <StyledTableHeader></StyledTableHeader>
          </StyledTableHeadRow>
        </StyledTableHead>
        <StyledTableBody>
          {catalogue.map(function (row) {
            const { id, name, unit, price, quantity, attributes = [] } = row;
            const option = {
              value: id,
              label: name,
            };
            const inputId = `name_${id}`;
            const total = price * quantity;
            const hasError = errors.id === id;
            const showAttributes = attributes.length > 0;
            return (
              <>
                <StyledTableRow key={id}>
                  <StyledTableData $mini>
                    <SelectInput
                      name={id}
                      value={option}
                      isLoading={false}
                      isClearable={true}
                      isBorderLess={true}
                      isSearchable={true}
                      disabled={isReadOnly}
                      options={itemOptions}
                      onChange={onItemChange}
                      ariaLabelledBy={inputId}
                      placeholder="Select item"
                      errorMessage={errors.item}
                      caption={`Item Name-${name}`}
                      menuPortalTarget={window.document.body}
                    />
                    {hasError ? (
                      <StyledErrorMessage aria-live="polite">
                        {errors.name}
                      </StyledErrorMessage>
                    ) : null}
                  </StyledTableData>
                  <StyledTableData $mini></StyledTableData>
                  <StyledTableData $mini aria-disabled={isReadOnly}>
                    <StyledQuantityWrapper>
                      {isReadOnly ? null : (
                        <StyledRoundButton
                          $minus
                          data-testid={`minus-button-${id}`}
                          onClick={onQuantityChange.bind(null, id, '-')}
                        >
                          -
                        </StyledRoundButton>
                      )}
                      <StyledInput
                        type="number"
                        name="quantity"
                        value={quantity}
                        placeholder="qty"
                        id={`quantity_${id}`}
                        disabled={isReadOnly}
                        onChange={onInputChange}
                        data-testid={`quantity_${id}`}
                      />
                      {isReadOnly ? null : (
                        <StyledRoundButton
                          data-testid={`add-button-${id}`}
                          onClick={onQuantityChange.bind(null, id, '+')}
                        >
                          +
                        </StyledRoundButton>
                      )}
                      <StyledUnit>{unit}</StyledUnit>
                    </StyledQuantityWrapper>
                    {hasError ? (
                      <StyledErrorMessage aria-live="polite">
                        {errors.quantity}
                      </StyledErrorMessage>
                    ) : null}
                  </StyledTableData>
                  {!includeLeasePrice ? null : (
                    <>
                      <StyledTableData $mini aria-disabled={isReadOnly}>
                        {Number.isNaN(price) ? 0 : price.toLocaleString()}
                      </StyledTableData>
                      <StyledTableData $mini aria-disabled={isReadOnly}>
                        {Number.isNaN(total) ? 0 : total.toLocaleString()}
                      </StyledTableData>
                    </>
                  )}
                  <StyledTableData $mini>
                    {isReadOnly || catalogue.length === 1 || !name ? null : (
                      <StyledDeleteButton
                        data-testid={`delete-button-${id}`}
                        onClick={onRemoveItem.bind(null, id, null)}
                        title={`Remove ${name} from catalogue`}
                      >
                        <TrashIcon />
                      </StyledDeleteButton>
                    )}
                  </StyledTableData>
                </StyledTableRow>
                {showAttributes
                  ? attributes.map(function (attr) {
                      const {
                        id: attrId,
                        item_code: itemCode,
                        name: attributeName,
                        quantity: attributeQuantity,
                      } = attr;
                      const isLastRow =
                        attrId === attributes[attributes.length - 1].id;
                      return (
                        <StyledTableRow $sub={true} key={attrId}>
                          <StyledSubTableData />
                          <StyledSubTableData
                            $flex
                            $last={isLastRow}
                            aria-disabled={isReadOnly}
                          >
                            <StyledAttribute>{attributeName}</StyledAttribute>
                            <StyledAttribute $code>{itemCode}</StyledAttribute>
                          </StyledSubTableData>
                          <StyledSubTableData $last={isLastRow}>
                            <StyledQuantity aria-disabled={isReadOnly}>
                              {attributeQuantity}
                            </StyledQuantity>
                          </StyledSubTableData>
                          {includeLeasePrice ? (
                            <>
                              <StyledSubTableData $last={isLastRow} />
                              <StyledSubTableData $last={isLastRow} />
                            </>
                          ) : null}
                          <StyledSubTableData $last={isLastRow}>
                            {isReadOnly ? null : (
                              <StyledDeleteButton
                                data-testid={`delete-button-${attrId}`}
                                onClick={onRemoveItem.bind(null, id, attrId)}
                                title={`Remove ${attributeName} from catalogue`}
                              >
                                <TrashIcon />
                              </StyledDeleteButton>
                            )}
                          </StyledSubTableData>
                        </StyledTableRow>
                      );
                    })
                  : null}
              </>
            );
          })}
        </StyledTableBody>
        <StyledTableFoot>
          <StyledTableRow>
            <StyledTableData $mini>
              {isReadOnly ? null : (
                <StyledRowButton onClick={onAddItem}>
                  <StyledRoundIcon>+</StyledRoundIcon>
                  Add Item
                </StyledRowButton>
              )}
            </StyledTableData>
            <StyledTableData $mini></StyledTableData>
            <StyledTableData $mini aria-disabled={isReadOnly}>
              Total Items: {totalItems}
            </StyledTableData>
            <StyledTableData $mini></StyledTableData>
            {includeLeasePrice ? (
              <>
                <StyledTableData $mini aria-disabled={isReadOnly}>
                  Total:
                  <StyledCurrencyInput ref={currencyRef} disabled={isReadOnly}>
                    {currencyOptions.map(function (value) {
                      return <StyledOption key={value}>{value}</StyledOption>;
                    })}
                  </StyledCurrencyInput>
                  {isNaN(total) ? 0 : total.toLocaleString()}
                </StyledTableData>
                <StyledTableData $mini></StyledTableData>
              </>
            ) : null}
          </StyledTableRow>
        </StyledTableFoot>
      </StyledTable>
    </StyledWrapper>
  );
}

export default Catalogue;
