import Cookies from 'universal-cookie';

import {
  LOGIN_PATH,
  TOKEN_COOKIE_NAME,
  ORDER_DATE_FILTER,
  SIDE_NAV_ACTIVE_MENU,
} from 'constants/general';

/**
 * If both `name` and `value` are provided, set cookie value.
 * If only name is provided, remove cookie.
 * @param {{
 *  name: string
 *  value: string
 *  clear: boolean
 * }} param
 * @returns {string | null}
 */
function manageCookie({ name, value, clear = false }) {
  const cookies = new Cookies();
  if (name && value && !clear) {
    cookies.set(name, value);
    return value;
  }
  if (name && !clear && !value) {
    return cookies.get(name);
  }
  if (name && clear && !value) {
    cookies.remove(name);
    if (name === TOKEN_COOKIE_NAME) {
      cookies.remove(ORDER_DATE_FILTER);
      window.localStorage.removeItem(SIDE_NAV_ACTIVE_MENU);
      if (window.location.pathname.indexOf('login') === -1) {
        window.location.assign(LOGIN_PATH);
      }
    }
    return null;
  }
  return null;
}

export default manageCookie;
