/**
 * @description Returns a random generated string.
 * @param {number} length
 */
function getRandomString({ length = 5, prefix = '', upperCase = false } = {}) {
  let text = '';
  const chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++)
    text += chars.charAt(Math.floor(Math.random() * chars.length));
  if (prefix) {
    text = `${prefix}${text}`;
  }
  return upperCase ? text.toUpperCase() : text;
}

export default getRandomString;
